import React from 'react'
import '../../style/services.css'

function ServiceFeatures() {
    return (
        <>
            <div className='serviceFeatures_wrapper' >
                <div className="col-12 text-center">
                    <h1 className='col-12 my-4 aibased-heading'>Industries We Serve</h1>
                    {/* <p className='serviceFeatures_wrapper-p text-center m-0'>Welcome to [Your Company Name], where we offer a suite of innovative AI-based services designed to transform the way businesses operate. Our cutting-edge technology is tailored to meet the unique needs of companies, regardless of their size, providing a digital entity or AI employee that efficiently reduces the need for online assistance. Explore the services that can be crafted and customized for your specific requirements:</p> */}
                </div>
                <div className='col-12 my-5 service_features_list_container '>
                    <div className='col-10 justify-content-center d-flex '>
                        <div className='gap-3'>
                            <div className='service-feature-box'>
                                <div className='d-flex me-3 w-100 align-items-center'>
                                    <div>
                                        <img src={require('../../Assets/element-1.png')} alt="" />
                                    </div>
                                    <div className='service-feature-box-line'>
                                    </div>
                                </div>
                                <div className='service_features_list_contain'>
                                    <p className='service_features_list_head'>Optimize chains</p>
                                    <p className='service_features_list_descr my-2'>ManufacturingOptimize supply chains, manage production processes, and streamline operations with our custom ERP solutions designed for manufacturing.</p>
                                </div>
                            </div>
                            <div className='service-feature-box'>
                                <div className='d-flex me-3 w-100 align-items-center'>
                                    <div>
                                        <img src={require('../../Assets/element-2.png')} alt="" />
                                    </div>
                                    <div className='service-feature-box-line'>
                                    </div>
                                </div>
                                <div className='service_features_list_contain'>
                                    <p className='service_features_list_head'>Manage projects</p>
                                    <p className='service_features_list_descr my-2'>ConstructionManage projects, track resources, and collaborate effectively with our software
                                    tailored for the construction industry.</p>
                                </div>
                            </div>
                            <div className='service-feature-box'>
                                <div className='d-flex me-3 w-100 align-items-center'>
                                    <div>
                                        <img src={require('../../Assets/element-3.png')} alt="" />
                                    </div>
                                    <div className='service-feature-box-line'>
                                    </div>
                                </div>
                                <div className='service_features_list_contain'>
                                    <p className='service_features_list_head'>School management</p>
                                    <p className='service_features_list_descr my-2'>EducationFrom school management to student performance tracking, our education software helps you streamline administrative tasks and improve learning outcomes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServiceFeatures