import './App.css';
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './Screen/Home';
import UserLayoutScreen from './Screen/Layout';

function App() {
  return (
    <Router>
      <div className='main-wrapper'>
        <Routes>
          <Route path="/" element={<UserLayoutScreen
           />} >
             <Route path='/' element={<Home />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
