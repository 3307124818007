import React from 'react';

function Feature() {
    return (
        <>
            <div className='Feature-wrapper'>
                <div className='col-12 text-center'>
                    <div className='col-12 d-flex justify-content-center'>
                        <h1 className='text-center company-personal-ai py-4'>Services</h1>
                    </div>

                    <div className='col-12 d-flex justify-content-center  my-auto'>
                        <p className='col-12 col-xxl-8 col-xl-9 col-lg-10 col-md-11 col-md-10 description text-center  m-0'>Welcome to Logidust, Our MissionTo simplify complex business processes through technology, enabling companies to focus on what they do best. We believe in creating software that’s not just functional but also user-friendly, intuitive, and impactful.</p>
                    </div>
                </div>
                <div className='features_list_container '>
                    <div className='mt-5'>
                        <div>
                            <div className='feature-box'>
                                <div className='features_list_contain'>
                                    <p className='features_list_head'>ERP Systems</p>
                                    <p className='features_list_descr my-2'>Our ERP solutions are designed to optimize your business processes, whether you're in manufacturing, construction, or school management. Centralize operations, manage resources efficiently, and drive growth with our integrated platforms.</p>
                                </div>
                            </div>
                            <div className='feature-box'>
                                <div className='features_list_contain'>
                                    <p className='features_list_head'>CRM Solutions</p>
                                    <p className='features_list_descr my-2'>Strengthen customer relationships and improve sales processes with our custom CRM software. Our solutions help you stay connected with your clients, automate sales pipelines, and increase customer satisfaction.</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='feature-box'>
                                <div className='features_list_contain'>
                                    <p className='features_list_head'>Custom Software Development</p>
                                    <p className='features_list_descr my-2'>Every business is unique, and so are our solutions. We
                                        develop mobile, web, and desktop applications that are tailored to your specific requirements.
                                        Whether you're launching a new product or upgrading existing systems, we’re here to make it
                                        happen.
                                    </p>
                                </div>
                            </div>
                            <div className='feature-box'>
                                <div className='features_list_contain'>
                                    <p className='features_list_head'>Graphic Design & MVP</p>
                                    <p className='features_list_descr my-2'>ServicesNeed to validate your idea? We offer concept designs and
                                        MVP development to help you bring your ideas to life. Our graphic design services also ensure
                                        your product looks as good as it performs.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>

    )
}

export default Feature