
import React from 'react';
import { RightOutlined } from '@ant-design/icons';

function Slider() {
    return (
        <>
            <div className='Slider-wrapper d-flex flex-column' >
                <div>
                    <div style={{ margin: '50px' }} className='d-flex flex-wrap justify-content-evenly align-items-center'>
                        <div className='col-12 col-md-7 text-md-start text-center'>
                            <div>
                                <h1 className='slider-heading'>
                                    Welcome to Logidust
                                </h1>
                                <p className='slider-sub-heading col-12 col-xxl-9 col-ls-7 col-md-10'>Powering Your Digital TransformationAt Logidust, we create innovative
                                    software solutions that empower businesses to achieve more. From ERP systems to custom
                                    web and mobile applications, we help you streamline processes, boost productivity, and
                                    enhance growth.</p>
                                <div>
                                    <div className='pt-3 d-flex flex-column gap-3'>
                                        <div className='d-flex gap-1 slider-sub-heading-inner flex-column'>
                                            {/* <img className='img-fluid' src={require('../../Assets/ai.svg.png')} alt="" /> */}
                                            <p className='m-0'><b>ERP Solutions :-</b></p>
                                            <p className='m-0'>Tailored software for businesses, manufacturing, construction, and schools</p>
                                        </div>
                                        <div className='d-flex gap-1 slider-sub-heading-inner flex-column'>
                                            {/* <img className='img-fluid' src={require('../../Assets/launch.svg fill.png')} alt="" /> */}
                                            <p className='m-0'><b>CRM Software :-</b></p>
                                            <p className='m-0'>Manage customer relationships effectively with our custom solutions.</p>
                                        </div>
                                        <div className='d-flex gap-1 slider-sub-heading-inner flex-column'>
                                            {/* <img className='img-fluid' src={require('../../Assets/relieved-01.png')} alt="" /> */}
                                            <p className='m-0'><b>Custom Software Development :-</b></p>
                                            <p className='m-0'>Bespoke mobile, web, and desktop applications designed to meet your unique needs.</p>
                                        </div>
                                        <div className='d-flex gap-1 slider-sub-heading-inner flex-column'>
                                            {/* <img className='img-fluid' src={require('../../Assets/cursor.svg fill.png')} alt="" /> */}
                                            <p className='m-0'><b>Graphic Design & MVP Development :-</b></p>
                                            <p className='m-0'>Concept designs that bring your vision to life and deliver Minimum Viable Products quickly.</p>
                                        </div>
                                        <div className='col-12 d-flex' style={{ justifyContent: 'center' }}>
                                            <button className='slider-btn-learnmore text-decoration-none  fs-6'>
                                                <a style={{color:'white'}} href="#contact" className=' text-decoration-none  '>Learn More<RightOutlined /></a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='my-auto col-9 col-md-5'>
                            <div className=''>
                                <img className='img-fluid' alt='' src={require('../../Assets/b-1.1.png')} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Slider