/* eslint-disable no-undef */
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation, NavLink } from 'react-router-dom';
import '../style/Navbbar.css'

function Navigation() {
    const [isSticky, setSticky] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;

            if (offset > 50) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return (
        <div className={`Header-Navigation ${isSticky ? 'sticky' : 'sticky'}`}>
            <Navbar expand="lg">
                <Container fluid className="p-0" style={{justifyContent:'center'}}>
                    <Navbar.Brand as={Link} to="/">
                        <img src={require(isSticky ? "../Assets/logo.png" : "../Assets/logo.png")} alt="" className='logo img-fluid' />
                    </Navbar.Brand>

                    {/* <div className="d-block d-lg-none ms-auto me-2 signButtons">
                        <Button as={Link} to="/signin" variant="light" className="mx-1">Sign In</Button>
                        <Button as={Link} to="/signup" variant="outline-light" className="">Sign Up</Button>
                    </div> */}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    {/* <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto custom-nav-items p-3">
                            <NavLink className={"fw-light"} as={Link} to="/" activeclassName="active">Home</NavLink>
                            <NavLink className={"fw-light"} as={Link} to="/Solutions" activeclassName="active">Solutions</NavLink>
                            <NavLink className={"fw-light"} as={Link} to="/About" activeclassName="active">About Us</NavLink>
                            <NavLink className={"fw-light"} as={Link} to="/Services" activeclassName="active">Services</NavLink>
                            <NavLink className={"fw-light"} as={Link} to="/Why Choose Us" activeclassName="active">Why Choose Us</NavLink>
                            <NavLink className={"fw-light"} as={Link} to="/Contact" activeclassName="active">Contact</NavLink>
                        </Nav>
                    </Navbar.Collapse> */}
                    {/* <div className="d-none d-lg-block ms-5 signButtons">
                        <Button as={Link} to="/signin" variant="light" className=" mx-3">Sign In</Button>
                        <Button as={Link} to="/signup" variant="outline-light" className="">Sign Up</Button>
                    </div> */}
                </Container>
            </Navbar>
        </div>



    );
}

export default Navigation;
