import React, { useState } from 'react';
import { toast } from "react-toastify";

function GetInTouch() {
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        subject: '',
        message: ''
    });

    const [errors, setErrors] = useState({
        name: '',
        mobile: '',
        email: '',
        subject: '',
        message: ''
    });

    const [loading, setLoading] = useState(false); // Add loading state
    const [showPopup, setShowPopup] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name' && !/^[a-zA-Z\s]*$/.test(value)) return;
        if (name === 'mobile' && !/^\d*$/.test(value)) return;
        if (name === 'subject' && !/^[a-zA-Z\s]*$/.test(value)) return;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
            isValid = false;
        }
        if (!formData.mobile.trim()) {
            newErrors.mobile = 'Mobile is required';
            isValid = false;
        } else if (formData.mobile.length < 7) {
            newErrors.mobile = 'Mobile number must be at least 7 digits long';
            isValid = false;
        }
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Invalid email address';
            isValid = false;
        }
        if (!formData.subject.trim()) {
            newErrors.subject = 'Subject is required';
            isValid = false;
        }
        if (!formData.message.trim()) {
            newErrors.message = 'Message is required';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSendClick = async () => {
        const isValid = validateForm();
        if (isValid) {
            setLoading(true); // Start loading
            try {
                const response = await fetch('https://logidust-backend.onrender.com/send-email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });
                if (response.ok) {
                    setFormData({
                        name: '',
                        mobile: '',
                        email: '',
                        subject: '',
                        message: ''
                    });
                    toast.success('Message sent successfully!');
                    setLoading(false);
                    setShowPopup(true); // Show the thank you popup
                } else {
                    toast.error('Error sending message');
                }
            } catch (error) {
                toast.error('Error submitting form. Please try again later.');
            } finally {
                setLoading(false); // Stop loading
            }
        } else {
            toast.error('Please fill in all required fields');
        }
    };

    const handleClosePopup = () => {
        setShowPopup(false); // Close the popup
    };

    return (
        <div id='contact' className='GetInTouch_Wrapper'>
            <div className='containerr'>
                <div>
                    <div className='col-12 d-flex flex-column align-items-center mb-5'>
                        <h1 className='contact-us-h1'>Contact Us</h1>
                    </div>
                </div>
                <div className='col-12 d-flex w-100 justify-content-around'>
                    <div className='col-12'>
                        <div className='contact_form mx-auto getintouch-box'>
                            {/* Input fields */}
                            <div className='d-flex w-100 justify-content-around'>
                                <div style={{ width: '45%' }} className='d-flex flex-column justify-content-around'>
                                    <div style={{ width: '100%', marginBottom: '0px' }}>
                                        <input type="text" className="form-control-1" name="name" placeholder="Name" value={formData.name} onChange={handleChange} disabled={loading} />
                                        <br />
                                        <span className="error-form-1">{errors.name}</span>
                                    </div>
                                    <div style={{ width: '100%', marginBottom: '0px' }}>
                                        <input type="tel" minLength={7} maxLength={15} className="form-control-1" name="mobile" placeholder="Mobile" value={formData.mobile} onChange={handleChange} disabled={loading} />
                                        <br />
                                        <span className="error-form-1">{errors.mobile}</span>
                                    </div>
                                </div>
                                <div style={{ width: '45%' }} className='d-flex flex-column justify-content-around'>
                                    <div style={{ width: '100%', marginBottom: '0px' }}>
                                        <input type="email" className="form-control-1" name="email" placeholder="Email" value={formData.email} onChange={handleChange} disabled={loading} />
                                        <br />
                                        <span className="error-form-1">{errors.email}</span>
                                    </div>
                                    <div style={{ width: '100%', marginBottom: '0px' }}>
                                        <select
                                            className="form-control-1"
                                            name="subject"
                                            value={formData.subject}
                                            onChange={handleChange}
                                            style={{ padding: '15px' }}
                                            disabled={loading}
                                        >
                                            <option value="" disabled>Select Subject</option>
                                            <option value="ERP Systems">ERP Systems</option>
                                            <option value="CRM Solutions">CRM Solutions</option>
                                            <option value="Custom Software Development">Custom Software Development</option>
                                            <option value="Graphic Design & MVP">Graphic Design & MVP</option>
                                        </select>
                                        <br />
                                        <span className="error-form-1">{errors.subject}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '100%', margin: '0px 48px', marginBottom: '0px' }}>
                                <textarea style={{ height: '125p' }} className="form-control-2" name="message" rows="6" placeholder="Write Your Message" value={formData.message} onChange={handleChange} disabled={loading} />
                                <br />
                                <span className="error-form-1">{errors.message}</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Show loader when loading */}
                <div className='col-12 d-flex justify-content-center'>
                    {loading ? (
                        <div className="loader">
                            <button style={{ backgroundColor: '#1f1f28', color: 'white' }} className='getintouch-summit-btn' disabled={loading}>
                                loading.....
                            </button>
                        </div>
                    ) : (
                        <button style={{ backgroundColor: '#1f1f28', color: 'white' }} className='getintouch-summit-btn' onClick={handleSendClick} disabled={loading}>
                            Send
                        </button>
                    )}
                </div>

            </div>
            {/* Thank You Popup */}
            {showPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <div className='popup-content-inner'>
                            <h2>Thank You!</h2>
                            <p>Your message has been sent successfully.</p>
                            <button className='slider-btn-learnmore text-decoration-none  fs-6' onClick={handleClosePopup}>Close</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default GetInTouch;
