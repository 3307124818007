import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import '../style/authentication.css';



function Ifooter() {

    return (
        <>
            <div className='footer'>
                <div className='f-uper'>
                    <div className='f-card'>
                        <div className='f-card-heading'>
                            {/* <h3>About Us</h3> */}
                            {/* <img style={{filter:'invert(1)',padding:'5px 0px'}} src={require("../../Assets/images/Line 8.png")} alt='' /> */}
                        </div>
                        <div>
                            <img style={{filter:'invert(1)',marginBottom:'25px'}} src={require('../Assets/logo.png')} alt="" className='logo img-fluid' />
                            <p>Start Your Digital Journey with LogidustLet’s create something amazing together. Whetheryou're ready to launch a project or just want to talk through some ideas, we’re here for you.</p>
                        </div>
                        {/* <div style={{ paddingTop: '25px', display:'flex' }}>
                            <a href="https://twitter.com/i/flow/login" target="_blank" rel="noopener noreferrer" className='f-social-icon'>
                                <img style={{filter:'invert(1)'}} src={require("../../Assets/images/X-icon.png")} alt="" />
                            </a>
                            <a href="https://www.facebook.com/login" target="_blank" rel="noopener noreferrer" className='f-social-icon'>
                                <img style={{filter:'invert(1)'}} src={require("../../Assets/images/fb-icon.png")} alt="" />
                            </a>
                            <a href="https://in.linkedin.com/" target="_blank" rel="noopener noreferrer" className='f-social-icon'>
                                <img style={{filter:'invert(1)'}} src={require("../../Assets/images/in-icon.png")} alt="" />
                            </a>
                            <a href="https://www.instagram.com/accounts/login/" target="_blank" rel="noopener noreferrer" className='f-social-icon'>
                                <img style={{filter:'invert(1)'}} src={require("../../Assets/images/insta-icon.png")} alt="" />
                            </a>
                            <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer" className='f-social-icon'>
                                <img style={{filter:'invert(1)'}} src={require("../../Assets/images/yt-icon.png")} alt="" />
                            </a>
                        </div> */}

                    </div>
                    <div className='f-card'>
                        <div className='f-card-heading'>
                            <h3 className='mb-2'>Contact Info</h3>
                            {/* <img style={{filter:'invert(1)',padding:'5px 0px'}} src={require("../../Assets/images/Line 8.png")} alt='' /> */}
                        </div>
                        <div>
                            <div className='f-contact-info'>
                                <p className='f-contact-info-p'>Address :</p>
                                <p>Indore</p>
                            </div>
                            <div className='f-contact-info'>
                                <p className='f-contact-info-p'>Phone :</p>
                                <p>+9131625951,9329959370</p>
                            </div>
                            {/* <div className='f-contact-info'> 
                                <p className='f-contact-info-p'>E-mail :</p>
                                <p>aitech123@gmail.com</p>
                            </div> */}
                            <div className='f-contact-info'>
                                <p className='f-contact-info-p' style={{ whiteSpace: 'nowrap' }}>E-mail :</p>
                                <p >logidust@yahoo.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='f-white-line'></div>
                <div className='f-lower'>
                    <h6>© 2023 - 2024 Logidust All Right Reserved</h6>
                    {/* <h6 style={{ marginRight: '35px' }}><Link as={Link} to="/Terms&Conditions" className='f-terms'>Terms & Conditions</Link> | <Link as={Link} to="/PrivacyPolicy" className='f-terms'>Privacy Policy</Link></h6> */}
                </div>
            </div>
        </>
    )
}

function handleClick() {
    // Handle button click event
    console.log('Button clicked!');
}
export default Ifooter
