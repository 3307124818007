import React from 'react'

function ManageSoftware() {
    return (
        <>
            <div style={{ backgroundColor: '#ffffff' }}>
                <div>
                    <div>
                        <h1 className='col-12 manage_heading pt-4'>Why Choose Logidust?</h1>
                    </div>
                    <div className='Manage_software_wrapper'>
                        {/* <div className="col-12 col-md-5 d-flex flex-wrap text-md-start text-center justify-content-center justify-content-md-start gap-5">
                            <div className='manage_software_list col-12'>
                                <ul>
                                    <li className='manage-software-list-inner'>Tailored Solutions:</li>
                                    
                                    <li className='manage-software-list-inner'>Scalability</li>

                                    <li className='manage-software-list-inner'>Innovation</li>

                                    <li className='manage-software-list-inner'>Support</li>

                                    <li className='manage-software-list-inner'>Future-ready</li>

                                </ul>
                            </div>
                        </div> */}
                        <div className="w-100 col-12 col-md-5 d-flex flex-wrap text-md-start text-center justify-content-center justify-content-md-start gap-5">
                            <div className='manage_software_list col-12'>
                                <ul>
                                    <li className='manage-software-list-inner'>
                                        Tailored Solutions
                                        <div className='hover-box'>We provide custom software solutions</div>
                                    </li>
                                    <li className='manage-software-list-inner'>
                                        Scalability
                                        <div className='hover-box'>Our software scales as your business grows</div>
                                    </li>
                                    <li className='manage-software-list-inner'>
                                        Innovation
                                        <div className='hover-box'>We stay ahead with the latest technology</div>
                                    </li>
                                    <li className='manage-software-list-inner'>
                                        Support
                                        <div className='hover-box'>24/7 customer support for all your needs</div>
                                    </li>
                                    <li className='manage-software-list-inner'>
                                        Future-ready
                                        <div className='hover-box'>Our solutions are built for the future</div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        {/* <div className="col-9 col-xxl-4 col-md-5 text-center">
                            <img className='img-fluid' src={require('../../Assets/manageSoft.png')} alt="" />
                        </div> */}
                    </div>
                </div>

            </div>
        </>
    )
}

export default ManageSoftware