// UserLayoutScreen.jsx

import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Navigation from './Navigation';
import Ifooter from './ifooter';
import Home from './Home';

function UserLayoutScreen() {
    return (
        <div className='main-wrapper'>
            <Layout>
                <div><Navigation /></div>
                <Content>
                    <Routes>
                        <Route path="/" element={<Home />} />
                    </Routes>
                </Content>
                <div ><Ifooter /></div>
            </Layout>
        </div>
    );
}

export default UserLayoutScreen;
