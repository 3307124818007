import React from 'react'
import Slider from './subscreen/Slider'
import '../style/home.css'
import Feature from './subscreen/Feature'
import ManageSoftware from './subscreen/ManageSoftware'
import ServiceFeatures from './subscreen/ServiceFeatures'
import GetInTouch from './subscreen/GetInTouch'

function Home() {
  return (
    <div>
      <Slider />
      <Feature />
      <ServiceFeatures />
      <ManageSoftware />
      <GetInTouch />
    </div>
  )
}

export default Home
